import axios from 'axios'
import { getSessionStr } from "@/utils/session.js";

class HttpUpload {
  UploadImage(fileData, token, routeId, typeName, R) {
    const formData = new FormData();
    formData.append('image', fileData);
    formData.append('token', token);
    formData.append('routeId', routeId);
    formData.append('type', 99);
    formData.append('typeName', typeName);


    let session_ServerUrl = getSessionStr("ServerUrl");
    if (session_ServerUrl == "")
      session_ServerUrl = window.serverconfig.serverurl;

    axios.post(session_ServerUrl + '/api/app/saveImage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        R(response.data);
      })
      .catch(error => {
        console.error(error);
        R({ code: 1, message: '上传失败' });
      });
  }
}

export default HttpUpload