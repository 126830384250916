import axios from 'axios'
import { getSessionStr } from "@/utils/session.js";
//import { config } from 'vue/types/umd';

class HttpRequest {
  constructor() {
    this.queue = {};
  }

  getServerUrl() {
    //let env = process.env.NODE_ENV
    let session_ServerUrl = getSessionStr("ServerUrl");
    //return 'http://117.78.38.200/xunjian/'

    if (session_ServerUrl != "")
      return session_ServerUrl;
    else {
      return window.serverconfig.serverurl;
      /*
      if (env === 'development') {
        return 'http://117.78.38.200/xunjian/'
      } else {
        let url = window.location.href;
        if (url.indexOf("wx.patrolsystem.cn:8900") != -1) {
          return 'http://139.9.81.129/xunjian/'
        } else if (url.indexOf("test121.patrolsystem.cn:16885") != -1) {
          return 'http://117.78.38.200/xunjian/'
        }
      }
      */
    }
  }

  getInsideConfig() {
    const config = {
      baseURL: this.getServerUrl(),
      headers: {
        //'token': 'this.myToken.Token',
        'Content-Type': 'application/x-www-form-urlencoded',
        'I18N': (localStorage.getItem('locale') == "undefined" ? 'ZH' : localStorage.getItem('locale'))
      }
    }
    return config
  }

  destroy(url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }

  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      if (!Object.keys(this.queue).length) {
        // Spin.show()
      }
      this.queue[url] = true
      return config
    }, error => {
      return Promise.reject(error)
    })

    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(url)
      const { data, status } = res
      return { data, status }
    }, error => {
      this.destroy(url)
      console.log('axios error:', error)
      return Promise.reject(error)
    })
  }

  request(options, R) {
    const instance = axios.create({
      timeout: 10000,   //超时配置 3秒
      responseType: 'json',   // 响应数据格式
      responseEncoding: 'utf8',  // 响应数据编码        
      withCredentials: true // 允许携带cookie
    })

    if (options.data) {
      let data = options.data;
      let form = new FormData();
      let keys = Object.keys(options.data);
      keys.forEach(key => {
        form.append(key, data[key])
      });
      options.data = form;
    }

    options = Object.assign(this.getInsideConfig(), options)
    this.Log("request:" + options.url, options);
    this.interceptors(instance, options.url)

    //return instance(options)
    instance(options).then(res => {
      this.Log("response:" + options.url, res);
      if (res.status == 200)
        R(res.data);
      else
        R(null);
    }).catch(err => {
      this.Log(options.url, err);
      R(null);
    });
  }

  Log(tag, msg) {
    if (process.env.NODE_ENV == "development") {
      console.log("HTTP [" + tag + "]:", msg);
    }
  }
}


export default HttpRequest