import HttpRequest from "@/utils/httprequest"
import HttpUpload from "@/utils/httpupload"

const myhttp = new HttpRequest()

export default {}

export const GetServerUrl = () => {
    return myhttp.getServerUrl();
}

//获取验证码
export const GetCaptcha = (R) => {
    myhttp.request({
        url: '/captcha?t=' + Math.random(),
        method: 'get'
    }, R)
}

//用户登录
export const Login = (data, R) => {
    myhttp.request({
        url: '/api/login',
        method: 'post',
        data
    }, R);
}

//用户登录
export const UserIdLogin = (data, R) => {
    myhttp.request({
        url: '/api/userIdLogin',
        method: 'post',
        data
    }, R);
}

//企微用户登录
export const QYLogin = (data, R) => {
    myhttp.request({
        url: '/api/wwLogin',
        method: 'post',
        data
    }, R);
}

//用户注册
export const Register = (data, R) => {
    myhttp.request({
        url: '/api/register',
        method: 'post',
        data
    }, R)
}

//修改密码
export const ChangePassword = (data, R) => {
    myhttp.request({
        url: '/api/changePassword',
        method: 'post',
        data
    }, R)
}


//关闭通知
export const SetRecevieNoticeState = (data, R) => {
    myhttp.request({
        url: '/api/setIsRecevieNotice',
        method: 'post',
        data
    }, R)
}

//解除微信绑定
export const RemoveOpenID = (data, R) => {
    myhttp.request({
        url: '/api/removeOpenId',
        method: 'post',
        data
    }, R)
}

//获取公司名称
export const GetCompanyName = (cid, R) => {
    myhttp.request({
        url: '/api/getCompanyName',
        method: 'post',
        data: { companyId: cid }
    }, R)
}


//巡检查询
export const GetPollingQuery = (data, R) => {
    myhttp.request({
        url: '/api/getPatrolRecords',
        method: 'post',
        data
    }, R)
}

//巡检查询导出
export const ExportPollingQuery = () => {
    return myhttp.getServerUrl() + '/api/exportPatrolRecords';
}

//巡检查询明细
export const GetPollingQueryDetail = (data, R) => {
    myhttp.request({
        url: '/api/getPatrolRecordEvents',
        method: 'post',
        data
    }, R)
}


//巡检计划核查
export const GetPollingPlan = (data, R) => {
    myhttp.request({
        url: '/api/getPatrolPlanRecords',
        method: 'post',
        data
    }, R)
}

//巡检计划核查导出
export const ExportPatrolPlanRecords = () => {
    return myhttp.getServerUrl() + '/api/exportPatrolPlanRecords';
}

//巡检计划核查明细
export const GetPollingPlanDetail = (data, R) => {
    myhttp.request({
        url: '/api/getPatrolPlanRecordEvents',
        method: 'post',
        data
    }, R)
}

//巡检项目查询
export const GetItemQuery = (data, R) => {
    myhttp.request({
        url: '/api/getEventList',
        method: 'post',
        data
    }, R)
}

//临时巡检查询
export const GetTempPatrolRecords = (data, R) => {
    myhttp.request({
        url: '/api/app/getTempPatrolRecords',
        method: 'post',
        data
    }, R)
}

//合格率统计
export const GetPass = (data, R) => {
    myhttp.request({
        url: '/api/getPatrolRateList',
        method: 'post',
        data
    }, R)
}

//合格率统计导出
export const ExportPatrolRateList = () => {
    return myhttp.getServerUrl() + '/api/exportPatrolRateList';
}

//异常统计
export const GetUnusual = (data, R) => {
    myhttp.request({
        url: '/api/getEventAbnormalRateList',
        method: 'post',
        data
    }, R)
}

//提交备注说明
export const SaveComment = (data, R) => {
    myhttp.request({
        url: '/api/updatePatrolPlanRecordComment',
        method: 'post',
        data
    }, R)
}

//审核备注说明
export const AuditComment = (data, R) => {
    myhttp.request({
        url: '/api/updatePatrolPlanRecordAudit',
        method: 'post',
        data
    }, R)
}

//批量提交备注说明
export const BatchRemarkPatrolPlanRecords = (data, R) => {
    myhttp.request({
        url: '/api/batchRemarkPatrolPlanRecords',
        method: 'post',
        data
    }, R)
}

//批量审核备注说明
export const BatchAuditPatrolPlanRecords = (data, R) => {
    myhttp.request({
        url: '/api/batchAuditPatrolPlanRecords',
        method: 'post',
        data
    }, R)
}


//巡检轨迹
export const GetMapGPSRecords = (data, R) => {
    myhttp.request({
        url: '/api/app/getMapGPSRecords',
        method: 'post',
        data
    }, R)
}

//实时位置
export const GetRealTimeLocation = (data, R) => {
    myhttp.request({
        url: '/api/getRealTimeLocation',
        method: 'post',
        data
    }, R)
}

//报修记录
export const GetRepairPreview = (data, R) => {
    myhttp.request({
        url: '/api/app/getRepairPreview',
        method: 'post',
        data
    }, R)
}

//报修详情
export const GetRepairDetail = (data, R) => {
    //console.log("GetRepairPreview");
    //console.log(data);
    myhttp.request({
        url: '/api/app/getRepairDetail',
        method: 'post',
        data
    }, R)
}

//报修状态变更
export const UpdateRepairState = (data, state, R) => {
    let _url = "";
    if (state == 0)        //报修审核
        _url = "/api/reviewForRepair";
    else if (state == 1)   //报修处理中
        _url = "/api/setInProcessForRepair";
    else if (state == 2)    //报修完成
        _url = "/api/setFinishForRepair";
    myhttp.request({
        url: _url,
        method: 'post',
        data
    }, R)
}

//上传图片
export const UploadImages = (data, R) => {
    let myUpload = new HttpUpload()
    let _url = "/api/app/saveImage";
    myUpload.UploadImage(data.file, data.token, data.routerid, data.typeName, R);
}

//获取微信js-sdk配置信息
export const GetWeixinConfig = (url, R) => {
    myhttp.request({
        baseURL: window.serverconfig.weixinurl,
        url: '/api/weixin/GetWeixinConfig?url=' + url,
        method: 'get',
        data: null
    }, R)
}


//报修状态变更
export const Test = (R) => {
    myhttp.request({
        baseURL: window.serverconfig.weixinurl,
        url: '/api/weixin/Test',
        method: 'get',
        data: null
    }, R)
}
