export const setSessionStr = (key, value) => {
    sessionStorage.setItem(key, value);
}

export const getSessionStr = (key) => {
    let v = sessionStorage.getItem(key);
    return (!v || v == undefined) ? "" : v;
}

export const setSessionObj = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
}

export const getSessionObj = (key) => {
    let v = sessionStorage.getItem(key);
    return (!v || v == undefined) ? null : JSON.parse(v);
}

export const setLocalStr = (key, value) => {
    localStorage.setItem(key, value);
}

export const getLocalStr = (key) => {
    let v = localStorage.getItem(key);
    return (!v || v == undefined) ? "" : v;
}

export const setLocalObj = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const getLocalObj = (key) => {
    let v = localStorage.getItem(key);
    return (!v || v == undefined) ? null : JSON.parse(v);
}